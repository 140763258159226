let lastHeight = 0
let height = 0

const onEmbedMessage = (event) => {
  function resize () {
    height = document.getElementById('wrapping-container').offsetHeight
    if (height === lastHeight) {
      return
    }

    lastHeight = height
    /**
     * It is usually considered a security vunerability to not check event.origin here. We don't
     * know the origin because Accelerator can be embedded by any remote origin. This is considered
     * okay here because we don't pass any sensitive data but rather just the height of the document
     */
    if (event.source.postMessage) {
      event.source.postMessage({
        height,
        /**
         * window.name is used here as it is persistent across iframe pages and iframe id is not
         * accessible due to security restrictions. We ensure that iframe name in the parent
         * document embed is the same as the iframe id.
         *
         * The alternative is to enable CORS.
         */
        iframeId: window.name,
        type: 'accelerator.embed.resize',
      }, event.origin)
    }
  }
  setInterval(resize, 200)
}

// Check if we are in an iframe, and if so we know that we are being embedded
// Report back the current window size
if (window.self !== window.top) {
  if (window.addEventListener) {
    window.addEventListener('message', onEmbedMessage, false)
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', onEmbedMessage)
  }
}
